<template>
  <div>
    <el-table :data="tableData" stripe style="width: 80%">
      <el-table-column prop="date" label="同步对象"> </el-table-column>
      <el-table-column prop="name" label="上次同步时间"> </el-table-column>
      <el-table-column prop="address" label="操作">
        <template>
          <el-button>立即同步</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
    };
  },
};
</script>

<style>
</style>